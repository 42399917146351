import React from 'react';
import Select, {MenuPlacement, OptionsType, Styles, ValueType} from 'react-select';
import ValidateFieldRequired from '../ValidateFieldRequired';
import {InputActionMeta} from "react-select/src/types";
import useTranslate from "../../../hooks/useTranslate";
import {NumberOption, StringOption} from "../../../types/Select";

interface IProps {
  [key: string]: any;

  id: string;
  required?: boolean;
  name: string;
  value?: ValueType<any, any>;
  options: OptionsType<any>;
  invalid?: boolean;
  defaultValue?: ValueType<any, any>;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  sortOptions?: boolean;
  loading?: boolean;
  noOptionsMessage?: string
  inputValue?: string
  formatOptionLabel?: (item: any) => JSX.Element
  isClearable?: boolean
  menuPosition?: 'absolute' | 'fixed'
  onChange?: (value: ValueType<any, any>) => void;
  menuPlacement?: MenuPlacement
  styles?:  Partial<Styles<any, false, any>>
  filterOption?: (option: StringOption | NumberOption, rawInput: string) => boolean;
  onInputChange?: (value: string, action: InputActionMeta) => void;
}

const SelectComponent = (props: IProps) => {

  const {
    id,
    name,
    value,
    onChange,
    className,
    options,
    placeholder,
    required,
    disabled,
    sortOptions = true,
    loading,
    inputValue,
    formatOptionLabel,
    defaultValue, isClearable = false,
    onInputChange,
    noOptionsMessage,
      menuPlacement, styles,
    menuPosition, filterOption
  } = props;
  const {t} = useTranslate("common");
  let opts: OptionsType<any> = [];
  if (options && options.length > 0) {
    if (sortOptions) {
      opts = [...(options as { label: string, value: string }[])].filter(item => item.label).sort((a, b) => a.label.toString().localeCompare(b.label.toString()))
    } else {
      opts = [...options]
    }
  }
  return <ValidateFieldRequired
    value={value}
    required={required}
    disabled={disabled}>
    <>
      <Select isMulti={false}
              classNamePrefix="react-select"
              placeholder={placeholder? placeholder:`${t('select_placeholder')}`}
              className={className}
              id={id}
              inputValue={inputValue}
              onInputChange={(e, action: InputActionMeta) => {
                if (onInputChange) {
                  if (action.action === "input-change") {
                    onInputChange(e, action);
                  }
                }
              }}
              isClearable={isClearable}
              menuPlacement={menuPlacement}
              filterOption={filterOption}
              noOptionsMessage={() => noOptionsMessage ?? "No options"}
              defaultValue={defaultValue}
              isLoading={loading}
              isDisabled={disabled}
              menuPosition={menuPosition ?? undefined}
              formatOptionLabel={(option) => { 
                if(formatOptionLabel) {
                  return formatOptionLabel?.(option);
                }
                return <>{option.label}</>;
              }}
              name={name}
              value={value}
              // styles={styles}
              styles={styles}
              onChange={(value: ValueType<any, any>) => onChange ? onChange(value, ) : null}
              options={opts}
      />
    </>
  </ValidateFieldRequired>;
};

export default SelectComponent;
