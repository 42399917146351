import * as React from 'react';
import TruncateComponent from "./TruncateComponent";
import {IsJsonString} from "../../utils";
import {AnalyticQueryAction, Report, TruncateReport} from "../../types/Analytic";
import styled from "styled-components";

type Props = {
    truncate: TruncateReport,
    action?: AnalyticQueryAction,
    index?: number,
    setTruncate?: (truncate: TruncateReport) => void,
    report: Report,
    getItemsFromTableColumn?: (column: string) => string[]
    launchQuery?: (query: Report, reload?: boolean) => void,
    setTruncateAction: (props: {
        action?: AnalyticQueryAction,
        nTruncate?: TruncateReport,
        report: Report,
        index?: number
    }) => void
    disabled?: boolean
    rangeType: string
    setRangeType: (type: string) => void
};
const Container = styled.div<{length: number}>`
    display: grid;
    grid-template-columns: ${props => `repeat(${props.length + 1}, auto)`};
    align-items: end;
    gap: 11px;
`
const TruncateWrapperComponent = ({
                                      truncate,
                                      action,
                                      index,
                                      report,
                                      setTruncate,
                                      disabled,
                                      setRangeType,
                                      rangeType,
                                      launchQuery,
                                      setTruncateAction,
                                      getItemsFromTableColumn
                                  }: Props) => {

    return <>
        {((truncate?.model || (truncate.field && truncate.name) || (truncate?.field && truncate?.format && (typeof truncate?.range === "number" || truncate.range))) && !truncate.dynamic_start) &&
        !truncate.relative ?
            <TruncateComponent setRangeType={setRangeType} rangeType={rangeType} disabled={disabled} truncate={truncate}
                               getItemsFromTableColumn={getItemsFromTableColumn} report={report}
                               setTruncate={(nTruncate, launch, newReport) => {
                                   if (IsJsonString(nTruncate) && !action) {
                                       setTruncate && setTruncate(JSON.parse(nTruncate))
                                   }
                                   if (launch) {
                                       setTruncateAction({
                                           action,
                                           index,
                                           nTruncate: IsJsonString(nTruncate) ? JSON.parse(nTruncate) as TruncateReport : undefined,
                                           report: {...(newReport ?? report)}
                                       })
                                   }
                               }} launchQuery={launchQuery}/> : null
        }

        <Container length={((truncate?.others?.length ?? 1) - 1) ?? 1} >
            {truncate?.others && truncate?.others.map((otherTruncate, index) => {
                return ((otherTruncate?.model || (otherTruncate?.field && otherTruncate?.type === "text" || otherTruncate?.type === "reference") || (otherTruncate?.field && otherTruncate?.format && otherTruncate?.range)) && !otherTruncate.dynamic_start) &&
                !otherTruncate.relative ?
                    <TruncateComponent setRangeType={setRangeType} rangeType={rangeType}
                                       truncate={otherTruncate} report={report}
                                       getItemsFromTableColumn={getItemsFromTableColumn}
                                       key={`truncate_report_${report._KEY ?? ""}_${otherTruncate.name ?? ""}_${otherTruncate.range}_${otherTruncate.format}_${index}`}
                                       setTruncate={(nTruncate, launch, newReport) => {
                                           if (truncate?.others) {
                                               const newTruncate: TruncateReport | string = IsJsonString(nTruncate) ? {
                                                   ...truncate,
                                                   others: truncate.others?.map((item, i) => {
                                                       if (i === index) {
                                                           return JSON.parse(nTruncate) as TruncateReport
                                                       }
                                                       return item
                                                   })
                                               } : ""
                                               if (Object.keys(newTruncate).length > 0 && !action && setTruncate) {
                                                   setTruncate(newTruncate as TruncateReport)
                                               }

                                               if (launch) {
                                                   setTruncateAction({
                                                       action,
                                                       index,
                                                       report: {...(newReport ?? report)},
                                                       nTruncate: newTruncate as TruncateReport
                                                   })
                                               }

                                           }

                                       }} launchQuery={launchQuery}/> : null
            })}
        </Container>


    </>
};

export default TruncateWrapperComponent