import {GroupConditionValue, SbxConditionType, SbxModelField} from "../../../types/Sbx";
import DateComponent from "../FieldComponents/DateComponent";
import {Input} from "reactstrap";
import NumberComponent from "../FieldComponents/NumberComponent";
import SmallTextComponent from "../FieldComponents/SmallTextComponent";
import React from "react";
import useTranslate from '../../../hooks/useTranslate';
import SelectComponent from "../FieldComponents/SelectComponent";
import RenderMonthElement from "../RenderMonthElement";
import {InputTags} from "react-bootstrap-tagsinput";

interface IPropsField {
  id: string;
  type?: SbxModelField | string;
  value: any;
  onChange: (value: any) => void;
  disabled: boolean
  condition?: GroupConditionValue
  fieldName?: string
}

export function getOptions(t?: (str: string) => string) {
  let date = new Date(), y = date.getFullYear(), m = date.getMonth();
  let firstDay = new Date(y, m, 1);
  let lastDay = new Date(y, m + 1, 0);
  return [
    {value: "${now}", label: t ? t("now") : "now", toValue: new Date()},
    {value: "${date_end_month}", label: t ? t("end_month") : "end_month", toValue: lastDay},
    {value: "${date_start_month}", label: t ? t("start_month") : "start_month", toValue: firstDay},
  ]
}

function getValueDate(value: any, t: (str: string) => string, fieldName?: string) {

  if (value) {
    const isOpt = getOptions(t).find(v => v.value === value)
    if (isOpt) {
      return isOpt.toValue;
    } else {
      return new Date(value.slice(0, -1))
    }
  }

  return null;
}

const FieldInput = ({type, value, onChange, id, disabled, condition, fieldName}: IPropsField) => {

  const {t} = useTranslate("common");
  switch (type) {

    case SbxModelField.DATE:
      return (
        <div className="d-flex">
          <div className="w-50">
            <DateComponent
              disabled={disabled}
              isOutsideRange={() => false}
              renderMonthElement={RenderMonthElement}
              id={id} value={getValueDate(value, t, condition?.FIELD?.value.name)}
              onChange={e => {
                // const date = new Date((e as Date).getFullYear(), (e as Date).getMonth(), (e as Date).getDate());
                let date = new Date(e as Date);


                if (condition?.FIELD?.value.name === "toDate" || fieldName === "toDate") {
                  date.setUTCHours(23, 59, 59)
                  onChange(date.toISOString())
                } else {
                  if (condition?.OP?.value.condition === SbxConditionType.GREATER_THAN) {
                    date.setUTCHours(0, 0, 0, 0);
                  } else if (condition?.OP?.value.condition === SbxConditionType.SMALLER_THAN) {
                    date.setUTCHours(23, 59, 59, 0)
                  } else {
                    date.setUTCHours(0, 0, 0, 0)
                  }

                  onChange(date.toISOString())
                }

              }}/>
          </div>
          <div className="w-50 ms-1">
            <SelectComponent
              id={""}
              name={""}
              onChange={e => onChange(e ? e.value : null)}
              options={getOptions(t)}
              value={getOptions(t).find(v => v.value === value) ?? null}/>
          </div>
        </div>
      )

    case SbxModelField.BOOLEAN:
      return <div className="d-flex justify-content-center align-items-center py-2 ">
        <div>
          <label className="text-success">
            <Input id={id}
                   name={id + "group"}
                   disabled={disabled}
                   type="radio"
                   checked={value}
                   onChange={() => onChange(true)}/>{" "}
            {t("common:true")}
          </label>
        </div>
        <div className="ms-5">
          <label className="text-gray">
            <Input id={id}
                   name={id + "group"}
                   disabled={disabled}
                   type="radio"
                   checked={!value}
                   onChange={() => onChange(false)}/>{" "}
            {t("common:false")}
          </label>
        </div>
      </div>

    case SbxModelField.INT:
    case SbxModelField.FLOAT:
      return <NumberComponent name=""
                              id={id}
                              disabled={disabled}
                              value={value || ""}
                              onChange={e => onChange(e)}/>
    case "ARRAY_STRING":
      return <div className="d-flex flex-column">
        <InputTags onTags={e => {
        onChange(e.values)
      }} values={value ? Array.isArray(value) ? value : [value] : []}/>

        <small className="text-gray">{t("hit_enter")}</small>
      </div>

    default:
      return <SmallTextComponent id={id}
                                 name=""
                                 disabled={disabled} value={value || ""}
                                 onChange={e => onChange(e)}/>
  }
}

export default FieldInput;
