import UserComponent from "../UserComponent/UserComponent";
import { capitalize } from "../../../utils";
import React, { useState } from "react";
import Link from "next/link";
import useTranslate from "../../../hooks/useTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { logoutService } from "../../../services/backend/AuthService";

interface IProps {
  fullName?: string;
  email?: string;
}

const LogoutCard = ({ fullName, email }: IProps) => {
  const [visible, setVisible] = useState(false);

  const show = visible ? "show" : "";

  const toggle = () => setVisible((prevState) => !prevState);
  const { t } = useTranslate("common");

  return (
    <div className="header-btn-lg pe-0">
      <div className="widget-content p-0">
        <div className="widget-content-wrapper flex-wrap">
          <div className="widget-content-left">
            <div className="btn-group">
              <a
                onClick={() => setVisible(!visible)}
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className={"p-0 btn " + show}
              >
                <UserComponent
                  name={fullName}
                  info={
                    <i
                      className={
                        "fa  ms-2 opacity-8 " +
                        (!visible ? "fa-angle-down" : "fa-angle-up")
                      }
                    />
                  }
                  className="d-flex align-items-center"
                />
              </a>
              <div
                tabIndex={-1}
                role="menu"
                aria-hidden="true"
                style={
                  visible
                    ? {
                        position: "absolute",
                        inset: "0px auto auto 0px",
                        margin: "0px",
                        transform: "translate(-308px, 65px)",
                      }
                    : {}
                }
                className={
                  "rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right " +
                  show
                }
              >
                <div className="dropdown-menu-header">
                  <div className="dropdown-menu-header-inner bg-info">
                    <div
                      className="menu-header-image opacity-2"
                      style={{
                        backgroundImage:
                          "url('/assets/images/dropdown-header/city3.jpg')",
                      }}
                    />
                    <div className="menu-header-content text-start">
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper flex-wrap">
                          <div className="widget-content-left me-3"></div>
                          <div className="widget-content-left">
                            <div className="widget-heading">
                              {capitalize(fullName)}
                            </div>
                            <div className="widget-subheading opacity-8">
                              {email}
                            </div>
                          </div>
                          {/*<LogoutButton/>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="scroll-area-xs" style={{ height: "150px" }}>
                  <div className="scrollbar-container ps">
                    <ul className="nav flex-column">
                      <li className="nav-item-header nav-item">
                        {t("options")}
                      </li>
                      <li className="nav-item my-1">
                        <Link href={"/auth/profile/proposal-profile"}>
                          <span className="nav-item px-3" onClick={toggle}>
                            <FontAwesomeIcon
                              icon={faUserAlt}
                              className="me-1"
                            />
                            {t("my_profile")}
                          </span>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <span
                          className="nav-item px-3 my-1 text-primary pointer"
                          onClick={() => {
                            logoutService();
                          }}
                        >
                          <FontAwesomeIcon icon={faPowerOff} className="me-1" />
                          {t("logout")}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="widget-content-left  ms-3 header-user-info">
            <div className="widget-heading">{capitalize(fullName)}</div>
            <div className="widget-subheading opacity-8">{email}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutCard;
