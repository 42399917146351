import ModalComponent from "../ModalComponent/ModalComponent";
import Form from "../../../../types/FormType";
import {useDispatch, useSelector} from "react-redux";
import {actionsModal, ModalTypes} from "../../../../store/Modal/Slice";
import {Button, FormGroup, Input, Label} from "reactstrap";
import React, {FormEvent, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";
import {actionsForm} from "../../../../store/Form/Slice";
import {RootState} from "../../../../store/Reducers";
import useIgnoreFirstEffect from "../../../../hooks/useIgnoreFirstEffect";
import {FormStates} from "../../../../store/Form/Types";
import useTranslate from "../../../../hooks/useTranslate";

interface IProps {
  open: boolean;
  data: IPropsFormModal
}

export interface IPropsFormModal {
  type: ModalTypes.UPSERT_FORM;
  form?: Form;
  title: React.ReactNode;
  labelName?: string;
}

const UpsertFormModal = (
  {
    open,
    data: {labelName, title, form}
  }: IProps) => {

  const dispatch = useDispatch();
  const [name, setName] = useState<string>(form?.name || "");
  const {state} = useSelector((state: RootState) => state.FormReducer);
  const {t} = useTranslate("common");

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    dispatch(actionsForm.upsertForm({name, id: form?.id}));
    dispatch(actionsModal.closeModal({type: ModalTypes.UPSERT_FORM}));
  }

  const toggle = () => dispatch(actionsModal.closeModal({type: ModalTypes.UPSERT_FORM}));

  useIgnoreFirstEffect(() => {
    if (state === FormStates.FORM_RESOLVED) {
      dispatch(actionsModal.closeModal({type: ModalTypes.UPSERT_FORM}))
    }
  }, [state])
  const loading = state === FormStates.FORM_PENDING;

  return (
    <ModalComponent
      title={title}
      isOpen={open}
      footer={<>
        <Button size="sm" color="primary" disabled={loading} className="me-1" form="form-create-id">
          <FontAwesomeIcon icon={loading ? faSpinner : faSave} spin={loading}/> {" " + t("save")}
        </Button>
        <Button size="sm" color="light" disabled={loading} onClick={toggle}>
          <FontAwesomeIcon icon={loading ? faSpinner : faTimes} spin={loading}/> {" " + t("cancel")}
        </Button>
      </>}
      toggle={toggle}>
      <form id="form-create-id"
            onSubmit={onSubmit}>
        <FormGroup>
          <Label>{labelName || "Name form"}</Label>
          <Input required value={name} onChange={e => setName(e.target.value)}/>
        </FormGroup>
      </form>
    </ModalComponent>
  )
}


export default UpsertFormModal;
