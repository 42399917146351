import { getDataFromKey, toJSON, toMap } from "./index";
import { FindQuery } from "sbx-lib-ts";

export enum OperatorQuery {
  and = "and",
  or = "or",
}

function validateValue<T>(item: T, value: string, exactly = false) {
  if (exactly) {
    return Object.values(item as any).some((t) => {
      return (
        String(t).length === value.length ||
        String(t).toLowerCase() === value.toLowerCase()
      );
    });
  }
  return JSON.stringify(Object.values(item as any))
    .toLowerCase()
    .trim()
    .includes(value.toLowerCase().trim());
}

const exactly = (item: any, field: string, searchData: string) =>
  getDataFromKey(item, field)?.length === searchData.length &&
  getDataFromKey(item, field)?.toLowerCase() === searchData.toLowerCase();
const slice = (item: any, field: string, searchData: string) =>
  getDataFromKey(item, field)?.slice(0, searchData.length).toLowerCase() ===
  searchData.toLowerCase();

const include = (item: any, field: string, searchData: string) =>
  getDataFromKey(item, field)?.toLowerCase().includes(searchData.toLowerCase());

const match = (item: any, keys: string[], searchData: string[]) => {
  const itemsInData = searchData.filter((searchData) =>
    keys.some((field) => include(item, field, searchData)),
  );
  if (itemsInData.length === searchData.length) return -1;
  return searchData.length - itemsInData.length;
};

export interface FilterQuery {
  values: string[];
  operator: OperatorQuery;
}

export class Query<T> {
  private items: T[] = [];
  private filters: FilterQuery[][] = [];

  constructor(items: T[]) {
    this.items = items;
  }

  setItems(items: T[]) {
    this.items = items;
    return this;
  }

  setGroup() {
    this.filters.push([]);
    return this;
  }

  removeGroups() {
    this.filters = [];
  }

  setCondition(conditions: FilterQuery) {
    if (this.filters.length) {
      this.filters[this.filters.length - 1].push(conditions);
    } else {
      this.setGroup();
      this.setCondition(conditions);
    }
    return this;
  }

  combine<B>(array: B[], key: string) {
    const map = toMap(array, key);
    this.items = this.items.map((item) => ({
      ...item,
      ...map[(item as any)[key]],
    }));
    return this;
  }

  findByKey(key: string) {
    if (this.filters.length) {
      this.items = this.items.filter((item: any) => {
        return this.filters.every((filters) => {
          return filters.some((filter) => {
            switch (filter.operator) {
              case OperatorQuery.and:
                return filter.values.every(
                  (value) => getDataFromKey(item, key) === value,
                );
              case OperatorQuery.or:
                return filter.values.includes(getDataFromKey(item, key));
            }
          });
        });
      });
    }
    return this;
  }

  find() {
    if (this.filters.length) {
      this.items = this.items.filter((item) => {
        return this.filters.every((filters) => {
          return filters.some((filter) => {
            switch (filter.operator) {
              case OperatorQuery.and:
                return filter.values.every((value) =>
                  validateValue(item, value),
                );
              case OperatorQuery.or:
                return filter.values.some((value) =>
                  validateValue(item, value),
                );
            }
          });
        });
      });
    }
    return this;
  }

  sort(keys: string[]) {
    const sorted = this.items.reduce((obj: Record<string, T>, a, index) => {
      const compare = (itemA: T) => {
        // when search is exactly is a priority "a" more the number of match with keys
        const exactlyCount = keys.filter((field) =>
          exactly(itemA, field, this.getSearch()),
        ).length;
        if (exactlyCount) return "a " + exactlyCount * -1;

        // when search is slice is a priority "b" more the number of match with keys
        const sliceCount = keys.filter((field) =>
          slice(itemA, field, this.getSearch()),
        ).length;
        if (sliceCount) return "b " + sliceCount * -1;

        // when the reverse search is exactly is a priority "c" more the number of match with keys
        const exactlyCountReverse = keys.filter((field) =>
          exactly(itemA, field, this.getReverseSearch()),
        ).length;
        if (exactlyCountReverse) return "c " + exactlyCountReverse * -1;

        // when the reverse search is slice is a priority "d" more the number of match with keys
        const sliceCountReverse = keys.filter((field) =>
          slice(itemA, field, this.getReverseSearch()),
        ).length;
        if (sliceCountReverse) return "d " + sliceCountReverse * -1;

        // when search by item is exactly is a priority "e" more the number of match with keys
        const exactlyBySearch = this.getAllFilters().filter((searchData) => {
          return keys.some((field) => exactly(itemA, field, searchData));
        });

        const sliceBySearch = this.getAllFilters().filter((searchData) => {
          return keys.some((field) => slice(itemA, field, searchData));
        });

        if (exactlyBySearch.length >= sliceBySearch.length)
          return "e " + exactlyBySearch.length * -1;
        if (sliceBySearch.length > exactlyBySearch.length)
          return "f " + sliceBySearch.length * -1;

        // when don't found search, match with keys is a priority "z"
        return "z " + match(itemA, keys, this.getAllFilters());
      };

      const result = compare(a);
      const keyIndex = result.toString() + " " + index;
      obj[keyIndex] = a;
      return obj;
    }, {});

    const sortPriority = Object.keys(sorted).sort((a, b) => {
      const [a1, a2] = a.split(" ");
      const [b1, b2] = b.split(" ");
      return `${a1} ${keys.length + parseInt(a2)}`.localeCompare(
        `${b1} ${keys.length + parseInt(b2)}`,
      );
    });
    this.items = sortPriority.map((key) => {
      return sorted[key];
    });
    return this;
  }

  getItems() {
    return this.items;
  }

  private getAllFilters() {
    return this.filters
      .flatMap((filters) => filters.flatMap((filter) => filter.values))
      .filter((v) => v.trim().length);
  }

  private getSearch() {
    return this.getAllFilters().join(" ");
  }

  private getReverseSearch() {
    return this.getAllFilters().reverse().join(" ");
  }
}

export const setFunctions = (text: string, query: FindQuery) => {
  const functions = text.split(";");
  const funcValues = functions.map((func) => {
    const [name, params] = func.replace("[", ":[").split(":");
    return {
      name: name.trim(),
      params,
    };
  });

  funcValues.forEach(({ name, params }) => {
    const _params = toJSON(params?.replaceAll("'", '"'));

    switch (name) {
      //
      case "fetchModels":
        query.fetchModels(_params);
        break;
      case "andWhereIsGreaterOrEqualTo":
        query.andWhereIsGreaterOrEqualTo(_params[0], _params[1]);
        break;
      case "andWhereIsLessOrEqualTo":
        query.andWhereIsLessOrEqualTo(_params[0], _params[1]);
        break;
      case "andWhereIsEqualTo":
        query.andWhereIsEqualTo(_params[0], _params[1]);
        break;
      case "newGroupWithAnd":
        query.newGroupWithAnd();
        break;
      case "newGroupWithOr":
        query.newGroupWithOr();
        break;
      case "andWhereIsIn":
        query.andWhereIsIn(_params[0], toJSON(_params[1]) || []);
        break;
      case "andWhereIsNotIn":
        query.andWhereIsNotIn(_params[0], toJSON(_params[1]) || []);
        break;
      case "andWhereIsNotNull":
        query.andWhereIsNotNull(_params[0]);
        break;
      case "andWhereIsNull":
        query.andWhereIsNull(_params[0]);
        break;
      case "andWhereIsGreaterThan":
        query.andWhereIsGreaterThan(_params[0], _params[1]);
        break;
      case "andWhereIsLessThan":
        query.andWhereIsLessThan(_params[0], _params[1]);
        break;
      case "orWhereIsGreaterOrEqualTo":
        query.orWhereIsGreaterOrEqualTo(_params[0], _params[1]);
        break;
      case "orWhereIsLessOrEqualTo":
        query.orWhereIsLessOrEqualTo(_params[0], _params[1]);
        break;
      case "orWhereIsNotEqualTo":
        query.orWhereIsNotEqualTo(_params[0], _params[1]);
        break;
      case "orWhereItStartsWith":
        query.orWhereItStartsWith(_params[0], _params[1]);
        break;
      case "orWhereItEndsWith":
        query.orWhereItEndsWith(_params[0], _params[1]);
        break;
      case "orWhereItContains":
        query.orWhereItContains(_params[0], _params[1]);
        break;
      case "orWhereIsIn":
        query.orWhereIsIn(_params[0], toJSON(_params[1]) || []);
        break;
      case "orWhereIsNotIn":
        query.orWhereIsNotIn(_params[0], toJSON(_params[1]) || []);
        break;
      case "whereWithKeys":
        query.whereWithKeys(_params);
        break;
      case "fetchReferencingModels":
        query.fetchReferencingModels(_params);
        break;
      case "setAutowire":
        query.setAutowire(_params);
        break;
      case "setPage":
        query.setPage(_params);
        break;
      case "orWhereIsEqualTo":
        query.orWhereIsEqualTo(_params[0], _params[1]);
        break;
      case "orWhereIsNotNull":
        query.orWhereIsNotNull(_params[0]);
        break;
      case "orWhereIsNull":
        query.orWhereIsNull(_params[0]);
        break;
      case "orWhereIsGreaterThan":
        query.orWhereIsGreaterThan(_params[0], _params[1]);
        break;
      case "orWhereIsLessThan":
        query.orWhereIsLessThan(_params[0], _params[1]);
        break;

      case "setPageSize":
        query.setPageSize(_params);
        break;
    }
  });

  return query;
};
